<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-tabs
      v-model="state.currentTabIndex"
      active-nav-item-class="font-weight-bold text-uppercase text-success"
    >
      <b-tab>
        <template #title>
          <i :class="iconState('ri-funds', state.currentTabIndex === 0)" /> Replenishments
        </template>
        <Replenishments
          ref="prPending"
          :busy="state.busy"
          :current-tab-index="state.currentTabIndex"
        />
      </b-tab>

    </b-tabs>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Replenishments from '@/views/da/reports/checks/Replenishments'

import formatter from '@/mixins/formatter'
import formulas from '@/mixins/formulas'
import misc from '@/mixins/misc'

export default {

  name: 'DAReportsChecksBase',

  middleware: ['auth', 'da'],

  metaInfo: () => ({
    title: 'Payment Request'
  }),

  components: {
    Replenishments
  },

  mixins: [formatter, misc, formulas],

  data () {
    return {
      state: {
        busy: false,
        currentTabIndex: this.getCurrentTabIndexByURL()
      },
      paymentRequest: {
        id: null,
        stage: 0,
        bp_code: null,
        bp_name: null,
        bp_tin: null,
        bp_partner_type: null,
        bp_vendor_type: null,
        bp_customer_type: null,
        bp_tax_status: null,
        bp_business_tax: null,
        bp_document_type: null,
        bp_tax_code: null,
        bp_tax_description: null,
        is_cash_advance: 0,
        customer_code: null,
        customer_name: null,
        customer_tin: null,
        amount: 0.00,
        budget_code: null,
        budget_name: null,
        item_code: null,
        item_name: null,
        budget_amount: null,
        particulars: null,
        bp_transaction_type: null,
        mop: null,
        branch: null,
        report_period: null,
        for_deposit: 1,
        deposit_bank: null,
        deposit_account_number: null,
        deposit_account_name: null,
        invoice_amount: 0.00,
        taxable_amount: 0.00,
        withholding_tax: 0.00,
        amount_due: 0.00,
        approvals: [],
        attachment_path: null,
        attachments: [],
        status: null,
        remarks: null,
        created_at: null
      },
      requesterSignatory: {
        stage: 0,
        approver_label: 'Requester',
        approver_action: 'Requisition',
        approver_name: null,
        last_seen: null,
        status: 'Requested',
        status_at: null,
        remarks: null
      },
      cancellationSignatory: {
        stage: 0,
        approver_label: 'Disbursement Head',
        approver_action: 'Cancellation of Request',
        approver_name: null,
        last_seen: null,
        status: 'Canceled',
        status_at: null,
        remarks: null
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    getCurrentTabIndexByURL () {
      switch (this.$route.params.da) {
        case 'histories' : {
          return 1
        }
        default: {
          return 0
        }
      }
    }

  }

}
</script>
